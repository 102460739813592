<template>
  <div class="flex items-center space-x-2">
    <input
      type="checkbox"
      :id="name + option.code"
      :checked="value"
      @input="$emit('input', option.code)"
      class="
        form-checkbox
        text-orange-900
        shadow-disable
        w-5
        h-5
        rounded
        border-grey-500
        hover:border-black-900
        cursor-pointer
      "
    />
    <label
      :for="name + option.code"
      class="text-base text-black-600 line-clamp-1"
    >
      {{ option.label }}</label
    >
    <!-- <span class="text-base text-grey-700">({{ option.count }})</span> -->
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    name: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
  },
};
</script>
